//react
import React, { useReducer, useEffect } from "react";

//Material-UI
import { Grid, Box, Divider, Typography } from "@mui/material";
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

//Date-Fns
import { isValid } from "date-fns";

//Axios
// import axios from 'axios';

//papa-parse
// import Papa from "papaparse";

//lodash
import { capitalize, get } from "lodash";

//CSS
import "./deduction.css";

//Local-Imports
import Deductions from "./Deductions/Deductions";
import { PAGINATION } from "utils/constants/pagination";
import { useDispatch, useSelector } from "store";
import {
  fetchDeductionData,
  fetchViewDeductionData,
  resetDeductions,
  reverseDeduction,
  runDeduction,
} from "store/reducers/deductions";
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import {
  convertDateIntoFrontEndFormat,
  dateFormat,
  getBEDateFormat,
} from "utils/date";
import CashDAutocomplete from "components/common/CashDAutocomplete";
import T from "utils/constants/T";
// import DeductionsView from './Deductions/DeductionActions/DeductionsView';
import RunDeduction from "./Deductions/DeductionActions/RunDeduction";
import ReverseDeduction from "./Deductions/DeductionActions/ReverseDeduction";
import {
  fetchDeductionTransactionBusinessUnitList,
  fetchDeductionTransactionPayPeriodList,
  fetchDeductionTransactionSchedulesList,
} from "store/reducers/deductionTransactionFilter";
import { DEDUCTION_STATUS_LIST } from "utils/constants/deductions";
// import { cashdCurrentUser } from 'utils/constants/validations';
import toast from "utils/ToastNotistack";
// import { decimalLimiter } from "utils/CommonMethods";
import { useNavigate } from "react-router";
import authApi from "interceptor/authInterceptor";
import Loader from "components/Loader";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const EXPORT_PAGE_SIZE = 10000;

const DeductionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const selectedCompanyName = get(selectedCompany, "company_name", "");
  const selectedCompanyId = get(selectedCompany, "_id", "");

  const {
    deductionTableList,
    deductionViewList,
    totalDeductionItems,
    totalDeductionPages,
    pageNo,
    pageSize,
  } = useSelector((state: any) => state.deductionData);

  const { scheduleList, businessUnitList } = useSelector(
    (state: any) => state.deductionTransactionFilter
  );
  const { systemPayrollCode } = useSelector(
    (state: any) => state.companyTabData
  );

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      page: pageNo,
      rowsPerPage: pageSize,
      schedule: null,
      deductionStatus: null,
      businessUnit: null,
      fromDate: null,
      toDate: null,
      openViewDeductionDialog: false,
      openRunDeductionDialog: false,
      openReverseDeductionDialog: false,
      payPeriod: null,
      companyId: "",
      viewPayPeriodId: "",
      deductionRecord: {},
      isLoading: false,
    }
  );

  const {
    page,
    rowsPerPage,
    schedule,
    deductionStatus,
    businessUnit,
    fromDate,
    toDate,
    openViewDeductionDialog,
    openRunDeductionDialog,
    openReverseDeductionDialog,
    payPeriod,
    companyId,
    viewPayPeriodId,
    deductionRecord,
    isLoading
  } = localState;

  //filters arrays
  const payPeriodId = payPeriod ? get(payPeriod, "_id", "") : null;
  const scheduleId = schedule ? get(schedule, "_id", "") : null;
  const businessUnitId = businessUnit ? get(businessUnit, "_id", "") : null;

  //filter listing arrays initial rendering
  useEffect(() => {
    if (selectedCompanyId) {
      setLocalState({ isLoading: true });
      dispatch(fetchDeductionTransactionPayPeriodList(selectedCompanyId));
      dispatch(fetchDeductionTransactionSchedulesList(selectedCompanyId));
      if (selectedCompanyName.includes(capitalize(T.HUDSON))) {
        dispatch(fetchDeductionTransactionBusinessUnitList(selectedCompanyId));
      }
    }
    setLocalState({ isLoading: false });
  }, [selectedCompanyId]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    companyId: string,
    payPeriodId: string,
    deductionRecord: any
  ) => {
    setLocalState({ isLoading: true });
    setAnchorEl(event.currentTarget);
    setLocalState({
      companyId: companyId,
      viewPayPeriodId: payPeriodId,
      deductionRecord,
      isLoading: false
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //View Deduction Modal opening handler
  const handleViewDeductionDailog = () => {
    setLocalState({ openViewDeductionDialog: !openViewDeductionDialog });
  };

  //Deduction run handler
  const handleRunDeductionDailog = () => {
    setLocalState({ openRunDeductionDialog: !openRunDeductionDialog });
  };

  //Reverse reduction handler
  const handleReverseDeductionDailog = () => {
    setLocalState({ openReverseDeductionDialog: !openReverseDeductionDialog });
  };

  //fetch view deduction table Data
  const handleViewDeduction = () => {
    dispatch(
      fetchViewDeductionData(
        INITIAL_PAGE,
        ROWS_PER_PAGE,
        companyId,
        viewPayPeriodId,
        deductionStatus
      )
    );
    setLocalState({ viewPayPeriodId: viewPayPeriodId, companyId: companyId });
    handleViewDeductionDailog();
    handleClose();
  };
  let viewDeductionRows: any = [];
  deductionViewList.map((item: any, index: number) => {
    viewDeductionRows[index] = {
      srNo: index + 1,
      employeeName: get(item, "staff.fullname", ""),
      employeeType: get(item, "staff.fullname", ""),
      totalAmountSent: get(item, "total_deductions_sent", ""),
      totalAmountUnsent: get(item, "total_amount_unsent", ""),
      totalCapitalSent: get(item, "total_amount_sent", ""),
      totalCapitalUnsent: get(item, "total_deductions_unsent", ""),
      totalFeeSent: get(item, "total_fee_sent", ""),
      totalFeeUnsent: get(item, "total_fee_unsent", ""),
    };
  });

  // const checkSalaryWag = (salaryWag: any, systemCode: any) => {
  //   let employeeType;
  //   if (systemCode === "DEPUTY") {
  //     employeeType = "Timesheet";
  //   } else {
  //     if (salaryWag === 2) {
  //       employeeType = "Timesheet";
  //     } else if (salaryWag === 3) {
  //       employeeType = "Salary & TimeSheet";
  //     } else {
  //       employeeType = "Salary";
  //     }
  //   }
  //   return employeeType;
  // };

  //Get view deduction data
  // const getViewDeductionExportData = (finalData: any) => {
  //   const filteredList = deductionTableList.filter(
  //     (item: any) => get(item, "_id", "") === viewPayPeriodId
  //   );
  //   console.log(filteredList)
  //   const company_name: any = get(
  //     filteredList,
  //     "[0].company_details.company_name",
  //     ""
  //   );
  //   const statusDeduction: any = get(
  //     filteredList,
  //     "[0].is_write_deductions_back_system",
  //     ""
  //   );
  //   const deductionPayPeriod: any = `${dateFormat(
  //     get(filteredList, "[0].start_date", "")
  //   )} - ${dateFormat(get(filteredList, "[0].end_date", ""))}`;
  //   const keyPayScheduleName: any = get(filteredList, "[0].pay_calendar_type", "")
  //   const scheduleName: any = get(
  //     filteredList,
  //     "[0].xero_pay_calendar.Name",
  //     ""
  //   )
  //     ? get(filteredList, "[0].xero_pay_calendar.Name", "")
  //     : get(filteredList, "[0].pay_period_origination.name", "")
  //   const systemCode: any = get(
  //     filteredList,
  //     "[0].company_details.system_code.code",
  //     ""
  //   );
  //   let exportViewDeductionRows: any = [];

  //   finalData.map((item: any, index: number) => {
  //     const totalAmountSent: any = get(item, "total_amount_sent", "");
  //     const amountSent = decimalLimiter(totalAmountSent)
  //     const totalAMountUnSent: any = get(item, "total_amount_unsent", "");
  //     const amountUnSent = decimalLimiter(totalAMountUnSent)
  //     const totalDeductionsSent: any = get(item, "total_deductions_sent", "");
  //     const deductionSent = decimalLimiter(totalDeductionsSent)
  //     const totalDeductionsUnSent: any = get(
  //       item,
  //       "total_deductions_unsent",
  //       ""
  //     );
  //     const deductionUnSent = decimalLimiter(totalDeductionsUnSent)
  //     const totalFeeSent: any = get(item, "total_fee_sent", 0);
  //     const feeSent = decimalLimiter(totalFeeSent)
  //     const totalFeeUnSent: any = get(item, "total_fee_unsent", 0);
  //     const feeUnSent = decimalLimiter(totalFeeUnSent)
  //     const employeeTYpe: any = checkSalaryWag(
  //       get(item, "salary_wag", ""),
  //       systemCode
  //     );
  //     const businessUnitID: any = get(item, "staff.business_unit_id", "");

  //     const businessUnitHudsonList: any = businessUnitList.filter(
  //       (item: any) => get(item, "_id", "") === businessUnitID
  //     );
  //     const businessUnitHudson: any = get(
  //       businessUnitHudsonList,
  //       "[0].name",
  //       ""
  //     );
  //     const payItemDescription = "CashD RTR";
  //     const payItemID = "PI000160"
  //     {
  //       systemPayrollCode !== "MYOBADVANCED"
  //         ? (exportViewDeductionRows[index] = {
  //           srNo: index + 1,
  //           CompanyName: company_name,
  //           BusinessUnit: businessUnitHudson ? businessUnitHudson : "N/A",
  //           FirstName: get(item, "staff.first_name", ""),
  //           LastName: get(item, "staff.last_name", ""),
  //           FullName: get(item, "staff.fullname", ""),
  //           PayrollID: get(item, "staff.system_employee_id", ""),
  //           CashDID: get(item, "staff.user_id", ""),
  //           EmployeeType: employeeTYpe ? employeeTYpe : "N/A",
  //           Schedule: scheduleName ? scheduleName : keyPayScheduleName,
  //           PayPeriod: deductionPayPeriod,
  //           Status: statusDeduction ? T.SENT : T.UNSENT,
  //           Capital: get(item, "total_amount_sent", "")
  //             ? `$ ${amountSent}`
  //             : `$ ${amountUnSent}`,
  //           Fee: get(item, "total_fee_sent", "")
  //             ? `$ ${feeSent}`
  //             : `$ ${feeUnSent}`,
  //           TotalAmount: get(item, "total_deductions_sent", "")
  //             ? `$ ${deductionSent}`
  //             : `$ ${deductionUnSent}`,
  //           NumberOfTransactions: get(item, "total_transactions_unsent", "")
  //             ? get(item, "total_transactions_unsent", "")
  //             : get(item, "total_transactions_sent", ""),
  //         })
  //         : (exportViewDeductionRows[index] = {
  //           "Ext. employee ID": get(item, "staff.system_employee_id", ""),
  //           "Ext. employee name": get(item, "staff.fullname", ""),
  //           "Ext. pay item ID": payItemID,
  //           "Ext. pay item description": payItemDescription,
  //           "Timesheet date": get(item, "", ""),
  //           Amount: get(item, "total_deductions_sent", "")
  //             ? deductionSent
  //             : deductionUnSent,
  //         });
  //     }
  //   });
  //   return exportViewDeductionRows;
  // };
  const handleExportViewTransaction = () => {
    authApi
      .get(
        `/v2/api/timesheets/getAllDeductions?page=0&pageSize=10&company_id=${companyId}&pay_period_id=${viewPayPeriodId}&schedule_id=&business_unit_id=&startDate=`
      )
      .then(async (res) => {
        const finalData = res.data.data;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Transactions');

        worksheet.mergeCells('A1:I1');
        const titleCell = worksheet.getCell('A1');
        titleCell.value = 'Withdrawals Reports';
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
        titleCell.font = { bold: true, size: 16, color: { argb: '0073e6' } };

        const companyName = selectedCompanyName;
        const startDate = get(finalData, '[0].start_date', 'N/A');
        const endDate = get(finalData, '[0].end_date', 'N/A');
        const payPeriod = `${startDate} to ${endDate}`;
        const dateReport = dayjs().format('DD-MM-YYYY');

        worksheet.mergeCells('A2:C2');
        worksheet.getCell('A2').value = `Company: ${companyName}`;
        worksheet.getCell('A2').alignment = { horizontal: 'left', vertical: 'middle' };

        worksheet.mergeCells('A3:C3');
        worksheet.getCell('A3').value = `Pay Period: ${payPeriod}`;
        worksheet.getCell('A3').alignment = { horizontal: 'left', vertical: 'middle' };

        worksheet.mergeCells('A4:C4');
        worksheet.getCell('A4').value = `Date Report: ${dateReport}`;
        worksheet.getCell('A4').alignment = { horizontal: 'left', vertical: 'middle' };

        worksheet.addRow([]);

        let runningTotal = 0;
        let feeTotal = 0;
        finalData.forEach((row: any) => {
          runningTotal += parseFloat(get(row, 'total_deduction', 0));
          feeTotal += parseFloat(get(row, 'transaction_fee_value', 0));
        });

        worksheet.getCell('G2').value = `Running Total: $${runningTotal.toFixed(2)}`;
        worksheet.getCell('G2').alignment = { horizontal: 'left', vertical: 'middle' };

        worksheet.getCell('G3').value = `Fee Total: $${feeTotal.toFixed(2)}`;
        worksheet.getCell('G3').alignment = { horizontal: 'left', vertical: 'middle' };

        const headers = ["#", "Company Name", "Staff Name", "Date", "Description", "Type", "Debit", "Credit", "Balance"];
        worksheet.getRow(5).values = headers;

        worksheet.getRow(5).eachCell((cell) => {
          cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0073e6' }
          };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        finalData.forEach((row: any, index: number) => {
          const formattedDate = dayjs(get(row, 'created_date')).format('DD-MM-YYYY h:mm:ss A');
          const newRow = worksheet.addRow([
            index + 1,
            selectedCompanyName,
            get(row, 'staff.fullname', ''),
            formattedDate,
            'Withdrawal to employee ' + get(row, 'staff.fullname', ''),
            'DD',
            '$ ' + get(row, 'total_deduction', 0) + ' (Inc. $ ' + get(row, 'transaction_fee_value', 0) + ' Fee)',
            get(row, 'credit', 0),
            '($' + get(row, 'total_deduction', 0) + ')'
          ]);
          const balanceCell = newRow.getCell(9);
          balanceCell.font = { color: { argb: 'FFFF0000' } };
        });

        worksheet.getColumn(6).numFmt = '$#,##0.00';
        worksheet.getColumn(7).numFmt = '$#,##0.00';

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, 'formatted_transactions.xlsx');
        setLocalState({
          viewPayPeriodId: viewPayPeriodId,
          companyId: companyId,
        });

        handleClose();
      }).catch((error) => {
        toast(get(error, "message", ""), { variant: "error" });
      });
  };
  const handleDeputyExportation = async (finalData: any) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Transactions');
    let totalSentAmount = 0;
    let totalUnsentAmount = 0;
    finalData.forEach((row: any) => {
      totalSentAmount += parseFloat(get(row, 'total_amount_sent', 0));
      totalUnsentAmount += parseFloat(get(row, 'total_amount_unsent', 0));
    });
    worksheet.mergeCells('A1:I1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = 'Summary Reports';
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
    titleCell.font = { bold: true, size: 16, color: { argb: '0073e6' } };

    const companyName = selectedCompanyName;
    const startDate = get(finalData, '[0].start_date', 'N/A');
    const endDate = get(finalData, '[0].end_date', 'N/A');
    const payPeriod = `${startDate} to ${endDate}`;
    const dateReport = dayjs().format('DD-MM-YYYY');

    worksheet.mergeCells('A2:C2');
    worksheet.getCell('A2').value = `Company: ${companyName}`;
    worksheet.getCell('A2').alignment = { horizontal: 'left', vertical: 'middle' };

    worksheet.mergeCells('A3:C3');
    worksheet.getCell('A3').value = `Pay Period: ${payPeriod}`;
    worksheet.getCell('A3').alignment = { horizontal: 'left', vertical: 'middle' };

    worksheet.mergeCells('A4:C4');
    worksheet.getCell('A4').value = `Date Report: ${dateReport}`;
    worksheet.getCell('A4').alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.getCell('G2').value = `Schedule Name: `;
    worksheet.getCell('G2').alignment = { horizontal: 'left', vertical: 'middle' };

    worksheet.mergeCells('G2:I2');
    worksheet.getCell('G3').value = `Sent Amount: $${totalSentAmount}`;
    worksheet.getCell('G3').alignment = { horizontal: 'left', vertical: 'middle' };

    worksheet.mergeCells('G3:I3');
    worksheet.getCell('G4').value = `Unsent Amount: $${totalUnsentAmount}`;
    worksheet.getCell('G4').alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.mergeCells('G4:I4');

    worksheet.addRow([]);
    const aggregatedData = finalData.reduce((acc: {
      [x: string]: {
        [x: string]: number; totalFeeSent: number;
      };
    }, row: any) => {
      const fullname = get(row, 'staff.fullname', 'Unknown');
      if (!acc[fullname]) {
        acc[fullname] = {
          fullname: fullname,
          totalAmountSent: 0,
          totalFeeSent: 0,
          totalAmountUnSent: 0,
          totalFeeUnSent: 0
        };
      }
      acc[fullname].totalAmountSent += parseFloat(get(row, 'total_amount_sent', 0));
      acc[fullname].totalFeeSent += parseFloat(get(row, 'total_fee_sent', 0));
      acc[fullname].totalAmountUnSent += parseFloat(get(row, 'total_amount_unsent', 0));
      acc[fullname].totalFeeUnSent += parseFloat(get(row, 'total_fee_unsent', 0));
      return acc;
    }, {});
    const groupedData = Object.values(aggregatedData);

    const headers1 = ["#", "Employees Name", "Employees Type", "Sent Amount", "", "", "Unsent Amount", "", ""];
    worksheet.getRow(5).values = headers1;
    worksheet.mergeCells('D5:F5');
    worksheet.mergeCells('G5:I5');

    const headers2 = ["", "", "", "Capital", "Fee", "Total", "Capital", "Fee", "Total"];
    worksheet.getRow(6).values = headers2;

    worksheet.getRow(5).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0073e6' }
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    worksheet.getRow(6).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0073e6' }
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    groupedData.forEach((row: any, index) => {
      const newRow = worksheet.addRow([
        index + 1,
        row.fullname,
        "Timesheet",
        '$' + row.totalAmountSent.toFixed(2),
        '$' + row.totalFeeSent.toFixed(2),
        '$' + (row.totalAmountSent + row.totalFeeSent).toFixed(2),
        '$' + row.totalAmountUnSent.toFixed(2),
        '$' + row.totalFeeUnSent.toFixed(2),
        '$' + (row.totalAmountUnSent + row.totalFeeUnSent).toFixed(2)
      ]);

      [newRow.getCell(4), newRow.getCell(5), newRow.getCell(6)].forEach(cell => {
        cell.font = { color: { argb: '32ff32' } };
      });
      [newRow.getCell(7), newRow.getCell(8), newRow.getCell(9)].forEach(cell => {
        cell.font = { color: { argb: 'ff0000' } };
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'transactions.xlsx');
  };
  const handleMyobExportation = async (finalData: any, filteredList: any) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Timesheet Template');

    const aggregatedData = finalData.reduce((acc: {
        [fullname: string]: {
            fullname: string;
            payroll_id: string;
            schedule_name: string;
            totalAmountSent: number;
            totalFeeSent: number;
            totalAmountUnSent: number;
            totalFeeUnSent: number;
        };
    }, row: any) => {
        const fullname = get(row, 'staff.fullname', 'Unknown');
        const payroll_id = get(row, 'staff.system_employee_id', '');

        if (!acc[fullname]) {
            acc[fullname] = {
                fullname: fullname,
                payroll_id: payroll_id,
                schedule_name: filteredList[0].schedule_name,
                totalAmountSent: 0,
                totalFeeSent: 0,
                totalAmountUnSent: 0,
                totalFeeUnSent: 0
            };
        }
        
        acc[fullname].totalAmountSent += parseFloat(get(row, 'total_amount_sent', 0));
        acc[fullname].totalFeeSent += parseFloat(get(row, 'total_fee_sent', 0));
        acc[fullname].totalAmountUnSent += parseFloat(get(row, 'total_amount_unsent', 0));
        acc[fullname].totalFeeUnSent += parseFloat(get(row, 'total_fee_unsent', 0));

        return acc;
    }, {});

    const groupedData = Object.values(aggregatedData);

    const headers1 = ["Ext. employee ID", "Ext. employee name", "Ext. pay item ID", "Ext. pay item description", "Timesheet date", "Amount"];
    worksheet.getRow(1).values = headers1;
    worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '7030a0' }
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    groupedData.forEach((row: any, index) => {
        worksheet.addRow([
            index + 1,
            row.fullname,
            row.payroll_id,
            'CashD RTR',
            row.schedule_name, 
            '$' + (row.totalAmountSent > 0 ? (row.totalAmountSent + row.totalFeeSent).toFixed(2) : (row.totalAmountUnSent + row.totalFeeUnSent).toFixed(2)),
        ]);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'summary.xlsx');
};

  //Export View deduction CSV
  const handleExportViewDeduction = () => {
    const filteredList = deductionTableList.filter(
      (item: any) => get(item, "_id", "") === viewPayPeriodId
    );
    const statusDeduction = get(filteredList, "[0].is_write_deductions_back_system", "");
    const deductionStatus = statusDeduction ? "sent" : "unsent";
    authApi
      .get(
        `/v2/api/companies/${companyId}/DeductionFiles/${viewPayPeriodId}?page=${INITIAL_PAGE}&pageSize=${EXPORT_PAGE_SIZE}&deduction_type=${deductionStatus}`
      )
      .then(async (res: { data: { result: any; }; }) => {
        const finalData = res.data.result;
        switch (systemPayrollCode) {
          case 'DEPUTY':
            handleDeputyExportation(finalData)
            break;
          case 'MYOBADVANCED':
            handleMyobExportation(finalData,filteredList);
            break;
          default:
            handleMyobExportation(finalData,filteredList);
            break;
        }
        setLocalState({ viewPayPeriodId: viewPayPeriodId, companyId: companyId });
        handleClose();
      })
      .catch((error) => {
        toast(get(error, "message", ""), { variant: "error" });
      });
  };


  const handleRunDeduction = () => {
    navigate(`/deductions/${companyId}/deductionFiles/${viewPayPeriodId}`, {
      state: { deductionTableList, deduction: deductionRecord },
    });

    handleClose();
  };
  const handleReverseDeduction = () => {
    setLocalState({ viewPayPeriodId: viewPayPeriodId, companyId: companyId });
    handleReverseDeductionDailog();
    handleClose();
  };

  const runSelectedDeductions = () => {
    dispatch(runDeduction(companyId, viewPayPeriodId));
    handleRunDeductionDailog();
  };
  const reverseSelectedDeductions = () => {
    dispatch(reverseDeduction(companyId, viewPayPeriodId));
    handleReverseDeductionDailog();
  };

  useEffect(() => {
    dispatch(resetDeductions());
    setLocalState({ isLoading: true })
    dispatch(
      fetchDeductionData(
        INITIAL_PAGE,
        ROWS_PER_PAGE,
        selectedCompanyId,
        fromDate,
        toDate,
        payPeriodId,
        scheduleId,
        businessUnitId,
        deductionStatus
      )
    );

    setLocalState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE, isLoading: false });
  }, [selectedCompanyId, payPeriod, schedule, businessUnit, deductionStatus]);

  useEffect(() => {
    if (fromDate && toDate) {
      setLocalState({ isLoading: true })
      dispatch(
        fetchDeductionData(
          INITIAL_PAGE,
          ROWS_PER_PAGE,
          selectedCompanyId,
          fromDate,
          toDate,
          payPeriodId,
          scheduleId,
          businessUnitId,
          deductionStatus
        )
      );
      setLocalState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE, isLoading: false });
    }
  }, [fromDate, toDate]);

  const getScheduleName = (data: any, systemCode: String) => {
    let scheduleName = { payPeriodName: "", payPeriodId: "" };
    if (systemCode == T.DEPUTY.toUpperCase()) {
      scheduleName.payPeriodName = get(data, "pay_period_origination.name", "");
      scheduleName.payPeriodId = get(data, "pay_period_origination._id", "");
    } else if (systemCode == T.KEYPAY.toUpperCase()) {
      scheduleName.payPeriodName = get(data, "keypay_pay_schedule.name", "");
      scheduleName.payPeriodId = get(data, "keypay_pay_schedule._id", "");
    } else {
      scheduleName.payPeriodName = get(data, "xero_pay_calendar.Name", "");
      scheduleName.payPeriodId = get(data, "xero_pay_calendar._id", "");
    }
    return scheduleName;
  };

  const deductionFinalData: any = [];
  const deductionPayPeriods: any = [];
  deductionTableList.map((data: any, index: number) => {
    const systemCode = get(data, "company_details.system_code.code", "");
    const scheduleData = getScheduleName(data, systemCode);
    deductionPayPeriods.push(
      `${convertDateIntoFrontEndFormat(
        data.start_date
      )} - ${convertDateIntoFrontEndFormat(data.end_date)}`
    );

    deductionFinalData[index] = {
      srNo: index + 1,
      company_name: get(data, "company_details.company_name", ""),
      company_id: get(data, "company_details._id", ""),
      pay_periods: `${dateFormat(data.start_date)} To ${dateFormat(
        data.end_date
      )}`,
      deduction_status: data.is_write_deductions_back_system
        ? T.SENT
        : T.UNSENT,
      withdrawal_status: data.is_prevent_withdrawals ? T.LOCKED : T.OPEN,
      date_time: convertDateIntoFrontEndFormat(
        get(data, "write_deductions_date", "")
      ),
      amount: data.is_write_deductions_back_system
        ? data.total_deductions_sent
        : data.total_deductions_unsent,
      pay_period_id: get(data, "_id", ""),
      isPreventWithdrawals: get(data, "is_prevent_withdrawals", ""),
      payPeriodDetails: scheduleData,
      systemCode: get(data, "company_details.system_code.code", ""),
    };
  });

  // Page change handler
  const handlePageChange = (newPage: number) => {
    setLocalState({ page: newPage, isLoading: true });
    dispatch(
      fetchDeductionData(
        newPage,
        rowsPerPage,
        selectedCompanyId,
        fromDate,
        toDate,
        payPeriodId,
        scheduleId,
        businessUnitId,
        deductionStatus
      )
    );
    setLocalState({ isLoading: false })
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (event: any) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value, isLoading: true });
    dispatch(
      fetchDeductionData(
        INITIAL_PAGE,
        value,
        selectedCompanyId,
        fromDate,
        toDate,
        payPeriodId,
        scheduleId,
        businessUnitId,
        deductionStatus
      )
    );
    setLocalState({ isLoading: false })
  };

  //Date change handler
  const onHandleDateChange = (newValue: any, type: string) => {
    const validDate = newValue ? new Date(newValue) : null;

    setLocalState({
      [type]:
        validDate && isValid(validDate) ? getBEDateFormat(validDate) : null,
    });
  };

  //Autocomplete value change handler
  const onHandleAutoCompleteChange = (type: string, newValue: any) => {
    setLocalState({ [type]: newValue });
  };

  //Handler for clearing the filter
  const handleClearFilters = () => {
    setLocalState({
      fromDate: null,
      toDate: null,
      payPeriod: null,
      schedule: null,
      businessUnit: null,
      deductionStatus: null,
      isLoading: true
    });
    dispatch(
      fetchDeductionData(
        INITIAL_PAGE,
        rowsPerPage,
        selectedCompanyId,
        null,
        null,
        "",
        "",
        "",
        null
      )
    );
    setLocalState({ isLoading: false })
  };

  //Render formatted export file data

  // const formattedExportData = (finalData: any[]) => {
  //     const deductionFinalData: any = [];
  //     finalData.map((data: any, index: number) => {
  //         const systemCode = get(data, "company_details.system_code.code", "");
  //         const scheduleData = getScheduleName(data, systemCode);

  //         deductionFinalData[index] = {
  //             srNo: index + 1,
  //             company_name: get(data, "company_details.company_name", ""),
  //             company_id: get(data, "company_details._id", ""),
  //             pay_periods: `${dateFormat(data.start_date)} To ${dateFormat(data.end_date)}`,
  //             deduction_status: data.is_write_deductions_back_system ? T.SENT : T.UNSENT,
  //             withdrawal_status: data.is_prevent_withdrawals ? T.LOCKED : T.OPEN,
  //             date_time: get(data, "write_deductions_date", "N/A"),
  //             amount: data.is_write_deductions_back_system ? data.total_deductions_sent : data.total_deductions_unsent,
  //             pay_period_id: get(data, "_id", ""),
  //             isPreventWithdrawals: get(data, "is_prevent_withdrawals", ""),
  //             schedule: get(scheduleData, "payPeriodName", "")
  //         }
  //     }
  //     );
  //     return deductionFinalData;
  // }

  //handle CSV Export
  // const handleCSVExport = () => {
  //     // const { REACT_APP_BACKEND_API_PRIVATE_BASE_URL: baseURL, REACT_APP_X_API_KEY: X_API_KEY } = process.env;
  //     const startDate = !fromDate ? "" : fromDate;
  //     const endDate = !toDate ? "" : toDate;
  //     const pay_period_id = payPeriodId ? payPeriodId : "";
  //     const schedule_id = scheduleId ? scheduleId : "";
  //     const business_unit_id = businessUnitId ? businessUnitId : "";
  //     const deduction_status = deductionStatus === T.SENT ? true : deductionStatus === T.UNSENT ? false : "";
  //     // const { token } = cashdCurrentUser();
  //     let pageCount = 0;
  //     // const headers = {
  //     //     headers: {
  //     //         'x-api-key': X_API_KEY,
  //     //         token,
  //     //         'Content-Type': 'application/json'
  //     //     },
  //     // }
  //     authApi.get(`/v2/api/companies/DeductionFiles?company_id=${selectedCompanyId}&page=${INITIAL_PAGE}&pageSize=${EXPORT_PAGE_SIZE}&from_date=${startDate}&to_date=${endDate}&pay_period_id=${pay_period_id}&schedule_id=${schedule_id}&business_unit_id=${business_unit_id}&deduction_status=${deduction_status}`)
  //         .then(res => {
  //             let finalData: any = [];
  //             finalData = res.data.result;
  //             const totalData = res.data.totalItem;
  //             const totalPagesCount = res.data.totalPage;
  //             if (totalData <= EXPORT_PAGE_SIZE) {
  //                 const finalExportData = formattedExportData(res.data.result)
  //                 const csvData = Papa.unparse(finalExportData);
  //                 exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
  //             }
  //             else {
  //                 for (let i = 1; i <= totalPagesCount; i++) {
  //                     const pay_period_id = payPeriodId ? payPeriodId : "";
  //                     const schedule_id = scheduleId ? scheduleId : "";
  //                     const business_unit_id = businessUnitId ? businessUnitId : "";
  //                     const startDate = fromDate ? fromDate : "";
  //                     const endDate = toDate ? toDate : "";

  //                     // const headers = {
  //                     //     headers: {
  //                     //         'x-api-key': X_API_KEY,
  //                     //         token,
  //                     //         'Content-Type': 'application/json'
  //                     //     },
  //                     // }
  //                     authApi.get(`/v2/api/companies/DeductionFiles?company_id=${selectedCompanyId}&page=${i}&pageSize=${EXPORT_PAGE_SIZE}&from_date=${startDate}&to_date=${endDate}&pay_period_id=${pay_period_id}&schedule_id=${schedule_id}&business_unit_id=${business_unit_id}&deduction_status=${deduction_status}`)
  //                         .then(res => {
  //                             pageCount++;
  //                             const newFinalData = res.data.result;
  //                             let revisedFinalData = [...finalData, ...newFinalData];
  //                             finalData = revisedFinalData;
  //                             if (i === totalPagesCount && pageCount === totalPagesCount) {
  //                                 const finalExportData = formattedExportData(finalData)
  //                                 const csvData = Papa.unparse(finalExportData);
  //                                 exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
  //                             }
  //                         }
  //                         )
  //                         .catch((error) => {
  //                             toast(get(error, "message", ""), { variant: "error" });
  //                         }
  //                         );
  //                 }
  //             }
  //         }
  //         )
  //         .catch((error) => {
  //             toast(get(error, "message", ""), { variant: "error" });
  //         });
  // }
  return (
    <>
      {(isLoading) && <Loader />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard
            title={
              <>
                <Grid container spacing={1} className="tableSearchBox">
                  <Grid item xs={12} sm={6} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="From Date"
                        inputFormat="DD/MM/YYYY"
                        value={fromDate}
                        onChange={(date) =>
                          onHandleDateChange(date, "fromDate")
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="To Date"
                        inputFormat="DD/MM/YYYY"
                        value={toDate}
                        onChange={(date) => onHandleDateChange(date, "toDate")}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {systemPayrollCode !== "MYOBADVANCED" &&
                    selectedCompanyId && (
                      <>
                        <Grid item xs={12} sm={6} md={3}>
                          <CashDAutocomplete
                            placeholder={T.SCHEDULE}
                            displayRemoveButton="none"
                            listDetails={scheduleList}
                            value={schedule}
                            getByLabelText={(option: any) =>
                              get(option, "name", "") || get(option, "Name", "")
                            }
                            onHandleChange={(event: any, newValue: any) =>
                              onHandleAutoCompleteChange("schedule", newValue)
                            }
                          />
                        </Grid>

                        {selectedCompanyId &&
                          selectedCompanyName.includes(
                            capitalize(T.HUDSON)
                          ) && (
                            <Grid item xs={12} sm={6} md={2}>
                              <CashDAutocomplete
                                placeholder={T.BUSINESS_UNIT}
                                displayRemoveButton="none"
                                listDetails={businessUnitList}
                                value={businessUnit}
                                getByLabelText={(option: any) =>
                                  get(option, "name", "")
                                }
                                onHandleChange={(event: any, newValue: any) =>
                                  onHandleAutoCompleteChange(
                                    "businessUnit",
                                    newValue
                                  )
                                }
                              />
                            </Grid>
                          )}
                      </>
                    )}
                  <Grid item xs={12} sm={6} md={2}>
                    <CashDAutocomplete
                      placeholder={T.STATUS}
                      displayRemoveButton="none"
                      listDetails={DEDUCTION_STATUS_LIST}
                      value={deductionStatus}
                      getByLabelText={(option: any) => option}
                      onHandleChange={(event: any, newValue: any) =>
                        onHandleAutoCompleteChange("deductionStatus", newValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={1}>
                    <Box
                      sx={{
                        display: "flex",
                        "& h6": {
                          m: 1,
                        },
                        "& hr": {
                          mx: 0.2,
                          my: 0.1,
                        },
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <Typography
                        variant="h6"
                        className="reset"
                        onClick={handleClearFilters}
                      >
                        {T.RESET}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
            content={false}
          // secondary={
          //     <Box sx={{ display: "block", pl: 4 }}>
          //         <Button startIcon={<FileDownloadOutlinedIcon />} variant='contained' onClick={handleCSVExport}>
          //             {T.EXPORT}
          //         </Button>
          //         {/* <Box onClick={handleExportFetch}>
          //             <CSVExport data={exportData} filename={'transactions.csv'} />
          //         </Box> */}
          //     </Box>
          // }
          >
            <ScrollX>
              <Deductions
                allTableRows={deductionFinalData}
                totalTableRowsCount={totalDeductionItems}
                totalPageCount={totalDeductionPages}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                handleClick={handleClick}
                handleClose={handleClose}
                handleViewDeduction={handleViewDeduction}
                open={open}
                anchorEl={anchorEl}
                handleExportViewDeduction={handleExportViewDeduction}
                handleExportViewTransaction={handleExportViewTransaction}
                deductionViewList={viewDeductionRows}
                onRowsPerPageChange={handleRowsPerPageChange}
                handleRunDeduction={handleRunDeduction}
                handleReverseDeduction={handleReverseDeduction}
              />
            </ScrollX>
          </MainCard>
        </Grid>
      </Grid>
      <RunDeduction
        openRunDeductionDialog={openRunDeductionDialog}
        handleRunDeductionDailog={handleRunDeductionDailog}
        runSelectedDeductions={runSelectedDeductions}
      />
      <ReverseDeduction
        openReverseDeductionDialog={openReverseDeductionDialog}
        handleReverseDeductionDailog={handleReverseDeductionDailog}
        reverseSelectedDeductions={reverseSelectedDeductions}
      />
    </>
  );
};

export default DeductionPage;
