/* eslint-disable */
export default class T {
  static S_NO = "S.No.";
  static NA = "NA";
  static COMPANY_NAME = "Company Name";
  static MEMBER_SINCE = "Member Since";
  static BUSINESS_UNIT = "Business Unit";
  static PAY_PERIOD = "Pay Period";
  static PAY_PERIODS = "Pay Periods";
  static AVAILABLE_BALANCE = "Available Balance";
  static TO = "To";
  static PAY_PERIOD_NAME = "Pay Period Name";
  static SCHEDULE = "Schedule";
  static DEDUCTION_STATUS = "Deduction Status";
  static WITHDRAWAL_STATUS = "Withdrawal Status";
  static RESET_FILTERS = "Reset Filters";
  static SHOW_MORE = "Show More";
  static SHOW_LESS = "Show Less";
  static AMOUNT = "Amount";
  static ACTION = "Action";
  static LIVE = "Live";
  static TEST = "Test";
  static TRUE = "True";
  static FALSE = "False";
  static ADD = "Add";
  static CHANGE = "Change";
  static INVALID_USER = "Invalid User(Neither CashD Nor Company Admin)";
  static ON = "On";
  static OFF = "Off";
  static RESET = "RESET";
  static YES = "Yes";
  static NO = "No";
  static PAYROLL_ID = "Payroll ID";
  static HUDSON = "hudson";
  static EMPLOYEE = "Employee";
  static EMPLOYEE_NAME = "Employee Name";
  static EMPLOYEE_TYPE = "Employee Type";
  static CLEAR_FILTERS = "Clear Filters";
  static SENT_AMOUNT = "Sent Amount";
  static UNSENT_AMOUNT = "Unsent Amount";
  static EMPLOYEE_ID = "Employee ID";
  static DATE = "Date";
  static TIME = "Time";
  static UNSENT = "Unsent";
  static SENT = "Sent";
  static MONOOVA = "Monoova";
  static TRANSACTION_ID = "Transaction ID";
  static DEBIT = "Debit";
  static FEE = "Fee";
  static STATUS = "Status";
  static PENDING = "Pending";
  static COMPLETED = "Completed";
  static NO_RECORDS = "No Records";
  static EXPORT_DATA = "Export Data";
  static EXPORT = "Export";
  static COMPANY = "Company";
  static KYB = "KYB";
  static FROM_DATE = "From Date";
  static TO_DATE = "To Date";
  static FINANCIAL_SETTINGS = "Financial Settings";
  static PAYROLL = "Payroll ";
  static BANKING_SETTINGS = "Banking Settings";
  static WHITELABELLING_SETTINGS = "Whitelabelling Settings";
  static WHITELABELLING_SETTINGS_HAS_BEEN_SAVED_SUCCESSFULY =
    "Whitelabelling settings have been saved successfully";
  static NO_LOGO_UPLOADED = "No Logo Uploaded";
  static FILE_SIZE_SHOULD_NOT_EXCEED =
    "Use only png. File size/image size should not exceed 50kb/250 x  90 px. Save without logo to display company name";
  static BRAND = "Brand";
  static WHITE_LABELLED = "White Labelled";
  static CUSTOM = "Custom";
  static CHOOSE_SETTING = "Choose Setting";
  static CHOOSE_COLOURS = "Choose Colours";
  static UPLOAD_LOGO = "Upload Logo";
  static LOGO_DELETED_SUCCESSFULLY = "Logo deleted successfully";
  static DEFAULT = "Default";
  static DEFAULT_TYPE = "default";
  static CUSTOM_TYPE = "custom";
  static EDIT = "Edit";
  static SAVE = "Save";
  static BACK = "Back";
  static CONTACT_PERSON = "Contact Person";
  static EMAIL = "Email";
  static PHONE_NUMBER = "Phone Number";
  static COMPANY_DETAILS = "Company Details";
  static ENTER_VERIFICATION_CODE = "Enter Verification Code";
  static AN_EMAIL_WTH_YOUR_CODE_HAS_BEEN_SENT_TO =
    "An email with your code has been sent to";
  static ARE_YOU_SURE_YOU_WANT_TO_ADD = "Are you sure you want to add";
  static ARE_YOU_SURE_YOU_WANT_TO_DELETE = "Are you sure you want to delete ?";
  static DELETE_CONFIRMATION =
    "The logo will be removed without saving the settings.";
  static NAME = "Name";
  static ABN = "ABN";
  static PAYROLL_SYSTEM = "Payroll System";
  static ATTENDANCE_SYSTEM = "Attendance System";
  static CITY = "City";
  static COMMENTS = "Comments";
  static CONFIRM = "Confirm";
  static CANCEL = "Cancel";
  static CASHD_STAFF_NAME = "CashD Staff Name";
  static VERIFICATION_COMPLETED_DATE = "Verification Completed Date";
  static REVERIFICATION_DUE_DATE = "Re-verification Due Date";
  static SET_STATUS = "Set Status ";
  static DOCUMENTS = "Documents ";
  static COMPLETED_BY = "Completed By";
  static SAVED_DOCUMENTS = "Saved Documents";
  static FINANCIAL = "Financial";
  static TOTAL_NO_OF_EMPLOYEES = "Total # of Employees";
  static MONTHLY_ACTIVE_EMPLOYEES = "Monthly Active Employees";
  static TOTAL_AMOUNT_PER_COMPANY = "Total $ Per Company";
  static TOTAL_AMOUNT_DRAWN_ON_THIS_PERIOD = "Total $ Drawn on this Period";
  static ALLOWABLE_PERCENTAGE_DRAWDOWN = "Allowable % Drawdown";
  static BALANCE_AVAILABALE = "Balance Available";
  static FLOAT_ALERT_THRESHOLD_AMOUNT = "Float Alert-Threshold Amount";
  static MINIMUM_WITHDRAWAL = "Minimum Withdrawal";
  static FLOAT_ALERT_RECIPIENTS = "Float Alert Recipients";
  static TRANSACTIONS = "Transactions  ";
  static DEDUCTIONS = "Deductions";
  static SETTINGS = "Settings";
  static SETTING = "Setting";
  static PRIMARY_COLOUR = "Primary Colour";
  static SECONDARY_COLOUR = "Secondary Colour";
  static LOGO = "Logo";
  static CLICK_TO_UPLOAD = "Click to upload";
  static TRANSACTION_FEE = "Transaction Fee";
  static SYNC_PAYROLL = "Sync Payroll";
  static SYNC_PAYMENT_PARTNER_TRANSACTIONS =
    "Sync Payment Partner Transactions";
  static DEDUCTION_FILE_CATEGORY_SELECTION =
    "Deduction File Category Selection";
  static CASH_D_CAPITAL = "CashD Capital";
  static CASH_D_FEE = "CashD Fee";
  static SELECT_OPTION = "Select Option";
  static CHOOSE_DEDUCTION_CATEGORY = "Choose deduction category";
  static COMPANY_DETAILS_HAS_BEEN_SAVED_SUCCESSFULY =
    "Company Details Has Been Saved Successfully";
  static NO_FILES_UPLOADED = "No Files Uploaded!";
  static UPLOAD_SUCCESS = "Uploaded successfully";
  static KYB_DETAILS_SAVED_STATEMENT =
    "KYB details has been updated successfully";
  static FIRST_SELECT_A_COMPANY =
    "Please select a company first from the search bar";
  static DOLLARTEXT = "DOLLAR";
  static DOLLAR = "$";
  static PERCENTAGE = "%";
  static PERCENT = "PERCENT";
  static ALL_COMPANIES = "All Companies";
  static BANKING = "Banking";
  static ENVIRONMENT = "Environment";
  static VALIDATE_BANK_ACCOUNT = "Validate Bank Account";
  static PAYMENT_PARTNER = "Payment Partner";
  static ACCOUNT_NUMBER = "Account Number";
  static NAME_OF_LENDER = "Name of Lender";
  static CREDIT = "Credit";
  static LENDER_ACCOUNT_NAME = "Lender Account Name";
  static FUNDING_TYPE = "Funding Type";
  static NAME_OF_BANK = "Name of Bank";
  static BALANCE = "Balance";
  static INTEREST = "Interest";
  static ACCOUNT_NAME = "Account Name";
  static DATE_AND_TIME = "Date & Time";
  static BSB = "BSB";
  static INTEREST_RATE = "Interest rate";
  static PAYMENT_PARTNER_SELECTION = "Payment Partner Selection";
  static CHOOSE_PAYMENT_PARTNER = "Choose Payment Partner";
  static LENDER = "Lender";
  static DOES_NOT_EXIST = "Does Not Exist";
  static LENDER_DETAILS = "Lender Details";
  static LAST_SYNC_DATE_AND_TIME = "Last Sync Date & Time";
  static UNVERIFIED = "Unverified";
  static COMPLETE = "Complete";
  static RE_SUBMISSION_REQUIRED = "Re-submission required";
  static TIME_SHEETS_STATUS = "Time Sheets Status";
  static INCLUDE_SUBMITTED = "Include Submitted";
  static LOCKED = "Locked";
  static OPEN = "Open";
  static RUN_DEDUCTION = "Run Deduction";
  static VIEW_DEDUCTION = "View Deduction";
  static VIEW_RUN_DEDUCTION = "View & Run Deduction";
  static DOWNLOAD_DEDUCTION = "Download Summary";
  static DOWNLOAD_TRANSACTIONS = "Download Transactions";
  static REVERSE_DEDUCTION = "Reverse Deduction";
  static CAPITAL = "Capital";
  static TOTAL = "Total";
  static SELECT_LENDER = "Select Lender";
  static PERIOD_DEDUCTION_FILE_DETAIL = "Period Deduction File Detail";
  static DO_NOT_INCLUDE_SUBMITTED = "Do Not Include Submitted";
  static ASTUTE = "Astute";
  static KEYPAY = "Keypay";
  static DEPUTY = "Deputy";
  static PAYROLL_SETTINGS_UPDATED_SUCCESSFULLY =
    "Payroll settings updated successfully";
  static FINACIAL_SETTINGS_UPDATED_SUCCESSFULLY =
    "Financial Settings Updated Successfully";
  static ARE_YOU_SURE_YOU_WANT_TO_RUN_DEDUCTIONS_OF_THIS_PAY_PERIOD =
    "Are You Sure You Want To Run Deductions Of This Pay Period?";
  static ARE_YOU_SURE_YOU_WANT_TO_UNDO_DEDUCTIONS_OF_THIS_PAY_PERIOD =
    "Are You Sure You Want To Undo Deductions Of This Pay Period?";
  static DEDUCTION_RUN_SUCCESSFULLY = "Deduction Run Sucessfully!";
  static DEDUCTION_REVERSED_SUCCESSFULLY = "Deduction Reversed Sucessfully!";
  static RESEND_OTP_SUCCESS = "OTP Resend Successfully!";
  static PAY_TYPE = "Pay Type";
  static REGISTRATION_DATE = "Registration Date";
  static DATE_TIME = "Date & Time";
  static CASHD = "CashD";
  static SUBMIT = "Submit";
  static INVITE_TO_ALL = "Invite to All";
  static UPLOAD = "Upload";
  static EMPLOYEES_TO_UPLOAD = "Employees to Upload";
  static EMPLOYEES_FOUND = "Employees Found";
  static INVITED = "Invited";
  static INVITATION = "Invitation";
  static ALREADY_REGISTERED = "Already Registered";
  static INVALID_EMAILS = "Invalid Emails";
  static INVITE = "Invite";
  static INVITATION_DATE = "Invite Date";
  static REMINDER = "Invitation Reminder";
  static EMAIL_TYPE = "Email Type";
  static BUSINESS_UNIT_ID = "Business Unit ID";
  static URL = "URL";
  static PLEASE_ENTER_URL = "Enter URL";
  static BUSINESS_UNIT_NAME = "Business Unit Name";
  static TEXT = "Text";
  static TEMPLATE_TYPE = "Template Type";
  static CENTER_TEXT = "Center Text";
  static FIND_OUT_URL = "Find Out URL";
  static UPLOAD_BANNER = "Upload Banner";
  static EMAIL_SETTINGS_HAS_BEEN_SAVED_SUCCESSFULY =
    "Email Settings Saved Successfully";
  static SETTING_SAVED = "Setting Saved";
  static PLEASE_ENTER_COMPANY_NAME = "Please Enter Company Name";
  static EMAIL_INVITE = "invitation";
  static INVITE_REMINDER = "invitationReminder";
  static SELECT = "Select";
  static MYOBADVANCED = "myobadvanced";

  // wallet variables
  static SALARY = "Salary (Cycle)";
  static SALARY_BALANCE = "Salary Balance (Cycle)";
  static LIMIT = "Limit";
  static CURRENT_FEE = "Current Fee";
  static WITHDRAWLS = "Withdrawals";
  static NO_PERMISSION =
    "You do not have access to this module. Kindly contact us at support@cashd.com.au";

  // timesheet specific
  static WORKING_HOURS = "Working Hours";
  static PAY_RATE = "Pay Rate";
  static SOMETHING_WENT_WRONG = "Something went wrong, please try again";

  // employee screen
  static PERSONAL_DETAILS = "Personal Details"
  static PAYROLL_DETAILS = "Payroll Details";
  static BANK_DETAILS = "Bank Details";
  static FIRST_NAME = "First Name";
  static LAST_NAME = "Last Name";
  static PHONE = "Phone";
  static ADDRESS = "Address";
  static FULL_NAME= "Full Name";
  static START_DATE = "Start Date";
  static BANK_ACCOUNT_NAME = "Bank Account Name";
  static BANK_BSB_NUMBER = "Bank BSB Number";
  static BANK_ACCOUNT_NUMBER = "Bank Account Number";
  static EMPLOYEE_EMAIL = "Employee Email";
  static MAXIMUM_WITHDRAWAL = "Maximum Withdrawal";
  static ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THE_CHANGES = "Are you sure you want to cancel the changes"
  static MAXIMUM_WITHDRAWAL_MONEY = "Limit Money"

  //webhook onboarding
  static API_INTEGRATION = "Api Integration";
  static WEBHOOKS = "Webhooks";
  static PAYROLL_INTEGRATION = "Payroll Integration";
}
