import T from "./T";

const {
// S_NO,
COMPANY_NAME,
DATE,
TIME,
MONOOVA,
TRANSACTION_ID,
DEBIT,
FEE,
STATUS,
PAY_PERIOD,
SCHEDULE,
EMPLOYEE,
PAYROLL_ID
} = T;
export const TRANSACTION_TABLE_HEADER = [
    // { label: S_NO },
    { label: `${DATE} & ${TIME}` },
    { label: COMPANY_NAME },
    { label: PAY_PERIOD },
    { label: SCHEDULE },
    { label: EMPLOYEE },
    { label: PAYROLL_ID, align:"center" },
    { label: `${MONOOVA} ${TRANSACTION_ID}`,align:"center"},
    { label: DEBIT },
    { label: FEE},
    { label: STATUS},
  ];